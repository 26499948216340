import {useRouter} from 'next/router';
import {constructServerSideProps} from 'utils/ssr';
import {Paths} from '@halp/foundation/Paths';
import {useSession} from '@halp/foundation/Users';
import {Head} from '@halp/foundation/App';
import {LoginForm, LoginLayout} from 'modules/Login';

export default function Login() {
	const {assignTokens, hasAuthToken} = useSession();
	const router = useRouter();
	const returnUrl = (router.query.returnUrl as string) ?? Paths.dashboard.url();

	function onLogin(result: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		data: {token: string; renew_token: string};
	}) {
		assignTokens({
			token: result.data.token,
			renewToken: result.data.renew_token,
		});
	}

	if (hasAuthToken) {
		router.push(returnUrl).catch((error) => {
			router.replace(Paths.dashboard.url());
			return Promise.reject(error);
		});
	}

	return (
		<LoginLayout>
			<Head title="Login" metaDescription="Login to your Halp Account" />
			<LoginForm onLogin={onLogin} redirect={returnUrl} />
		</LoginLayout>
	);
}

export const getServerSideProps = constructServerSideProps({
	dictionaries: ['login'],
});
